import React from 'react'
import {graphql, StaticQuery} from "gatsby"
import Img from 'gatsby-image'
import {Fade} from 'react-slideshow-image'
import 'react-slideshow-image/dist/styles.css'

const fadeProperties = {
    duration: 3500,
    transitionDuration: 500,
    infinite: true,
    indicators: false,
    arrows: false,
    // onChange: (oldIndex, newIndex) => {
    //     console.log(`fade transition from ${oldIndex} to ${newIndex}`);
    // }
}

const ImageSliderFade = () => {
    return(
        <StaticQuery
            query={graphql`
              query {
                allImageSharp {
                  edges {
                    node {
                      fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
                return (
                    <div className="slide-container">
                        <Fade {...fadeProperties}>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                         fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_oggi1.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona1.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona2.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                         fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_oggi2.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona3.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona4.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                         fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_oggi3.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona5.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                            <div className="each-fade">
                                <div className="image-container">
                                    <Img imgStyle={{width: "100%", height: "100%", objectFit: "cover"}}
                                               fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === "torre_ottagona6.jpg");
                                    }).node.fluid} alt={""} />
                                </div>
                            </div>
                        </Fade>

                    </div>
                )
            }}
        />
    )
}
export {ImageSliderFade};