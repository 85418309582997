import React from 'react';
import styled from "styled-components";

import {jsonConfig, useFetch} from "../utils/config";

import Countdown from "./countdown";

const StyledPrezzo = styled.span`
    white-space: nowrap; 
`;

const StyledPrezzoOld = styled.span`
    font-weight: 800;
    text-decoration: line-through; 
`;

const StyledPrezzoNew = styled.span`
    color: #db4437;
    font-weight: 800;
    font-size: 1.4rem;
`;

const StyledPercentage = styled.span`
    color: #db4437;
    font-size: 30px; 
    font-weight: 800;
    letter-spacing: -.05rem;
    background-color: ${props => props.theme.color.accent}; 
    padding: 25px 10px; 
    border-radius: 50px;
`;

const StyledSconto = styled.span`
    color: #db4437; 
    font-weight: 500;
`;

const Prezzo = ({children}) => {

    const { data } = useFetch(jsonConfig, {});

    const {prezzo, prezzo_scontato} = data;

    return (
    <>
        {prezzo && prezzo_scontato && prezzo_scontato !== "0" ?
            <>
                <StyledPrezzo>
                    <StyledPrezzoOld>EUR {prezzo}</StyledPrezzoOld>

                    <StyledPrezzoNew className={"ml-2"}>
                        EUR {prezzo_scontato}
                    </StyledPrezzoNew>

                </StyledPrezzo>
            </>

            :

            <span style={{color: "#db4437", fontWeight: "800"}}>
                EUR {prezzo}
            </span>
        }
        {children}
    </>
    )
};

const Sconto = ({children}) => {

    const { data } = useFetch(jsonConfig, {});

    const {prezzo, prezzo_scontato, percentuale_sconto, fine_promozione, contatore} = data;

    const data_fine_promozione = new Date(fine_promozione).toLocaleDateString();


    return (
        <>
            {prezzo && prezzo_scontato && prezzo_scontato !== "0" &&
                <>
                    <hr/>
                    <div className={"contaner"}>
                        <div className={"row align-items-center"}>
                            <div className={"col-4 col-sm-3 col-md-2"}>
                                <StyledPrezzo>
                                    <StyledPercentage>
                                        -{percentuale_sconto}%
                                    </StyledPercentage>
                                </StyledPrezzo>
                            </div>
                            <div className={"col-8 col-sm-9 col-md-10"}>
                                {fine_promozione && fine_promozione !== "0" && contatore === "FALSE" &&
                                <StyledSconto className={"d-block d-lg-inline-block ml-xl-2"}>
                                    Sconto valido fino al {data_fine_promozione}
                                </StyledSconto>
                                }
                                {fine_promozione && fine_promozione !== "0" && contatore === "TRUE" &&
                                <StyledSconto className={"d-block d-lg-inline-block ml-xl-2"}>
                                    Ancora per <Countdown/>
                                </StyledSconto>
                                }
                            </div>
                        </div>
                    </div>
                    <hr/>
                </>

            }
            {children}
        </>
    )
};

const Disponibile = () => {

    const {data} = useFetch(jsonConfig, {});

    const {disponibile} = data;

    return (
        <>
            <div style={{color: "#59a139", fontWeight: "600"}}>
                <p>{disponibile}</p>
            </div>
        </>
    );

};

const Spedizione = () => {

    const {data} = useFetch(jsonConfig, {});

    const {spedizione} = data;

    return (
        <>
            <span style={{fontWeight: "600"}}>
                {spedizione}
            </span>
        </>
    );

};


export {Prezzo, Sconto, Disponibile, Spedizione};