import React from "react";

const Title = ({type, as, className, style, children}) => (

    <>
    { type === "h1" &&
    <h1 className={`${as ? as : ''} ${className ? className : ''}`} style={style}>
        {children}
    </h1>
    }
    { type === "h2" &&
    <h2 className={`${as ? as : ''} ${className ? className : ''}`} style={style}>
        {children}
    </h2>
    }
    { type === "h3" &&
    <h3 className={`${as ? as : ''} ${className ? className : ''}`} style={style}>
        {children}
    </h3>
    }
    { type === "h4" &&
    <h4 className={`${as ? as : ''} ${className ? className : ''}`} style={style}>
        {children}
    </h4>
    }
    { type === "h5" &&
    <h5 className={`${as ? as : ''} ${className ? className : ''}`} style={style}>
        {children}
    </h5>
    }
    </>

);

export default Title;