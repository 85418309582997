import React from 'react';
import {graphql, StaticQuery} from "gatsby"
import Img from 'gatsby-image'
import styled from "styled-components";


const StyledDivImg = styled.div`
    text-align: center;
    
`;

const StyledImg = styled(Img)`
    border-radius: 50%;
    max-width: 130px;
    left: 50%;
    transform: translateX(-50%);
`;


const Col = ({children, className, style}) => (
    <div className={`"col-sm-12 col-lg-10 font-size-small justify-content-center align-self-center" ${className ? className : ''}`} style={style}>
        {children}
    </div>
);

const Bio = ({children, image, imageAlt}) => (
    <>
        <StyledDivImg className={"col-sm-12 col-lg-2 pt-2 pt-lg-0 justify-content-center align-self-center"}>
            <StaticQuery
                query={graphql`
              query {
                allImageSharp {
                  edges {
                    node {
                      fluid(maxWidth: 1200) {
                        ...GatsbyImageSharpFluid_withWebp_noBase64
                      }
                    }
                  }
                }
              }
            `}
                render={data => {
                    return (
                        <StyledImg fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                            // Match string after final slash
                            return (element.node.fluid.src.split('/').pop() === image);
                        }).node.fluid} alt={imageAlt} />
                    )
                }}
            />
        </StyledDivImg>
        {children}
    </>
);

export {Bio, Col};