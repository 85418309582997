import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle, faExternalLinkAlt, faExternalLinkSquareAlt} from "@fortawesome/free-solid-svg-icons"
import {Link} from "gatsby";
import { AnchorLink } from 'gatsby-plugin-anchor-links';

const Anchor = ({ href, children, info, modal, internal, external, style }) => (
    <>
    {(internal && info) &&
    <span>
        {internal &&
            <Link to={href} style={style}>
                {children}
                <FontAwesomeIcon icon={faExternalLinkSquareAlt} className={"ml-1"} style={{width: "13px"}}/>
            </Link>
        }
        {info &&
            <AnchorLink to={href} stripHash style={style} className="stripped">
                {children}
                <FontAwesomeIcon icon={faInfoCircle} className={"ml-1"} style={{width: "18px"}}/>
            </AnchorLink>
        }
    </span>
}
        {(!internal && !info) &&
            <a target={"_blank"} rel="noopener noreferrer" href={href} style={style}>
                {children}
                {modal && <FontAwesomeIcon icon={faExternalLinkAlt} className={"ml-1"} style={{width: "13px"}}/>}
                {external && <FontAwesomeIcon icon={faExternalLinkAlt} className={"ml-1"} style={{width: "13px"}}/>}
            </a>
        }
</>
);

export default Anchor;