import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from 'gatsby-image'
import styled from "styled-components"

const StyledCitazione = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};
    color: ${props => props.color ? props.color : `inherit`}; 
`;

const StyledBlockquote = styled.blockquote`    
    margin-right: 10px;
`;

const StyledImg = styled(Img)`    
    max-width: 80px;
    max-height: 80px;
    border-radius: 50%;
    left: 50%;
    transform: translateX(-50%);
`;

const Citazione = ({ background, color, className, children, style, image, imageAlt }) => (
    <>
        <StyledCitazione background={background} color={color} className={className} style={style}>
            <StyledBlockquote>
                <div className={"row"}>
                    <div className={"col-md-2 col-lg-3 pr-md-0 pr-lg-1 justify-content-center align-self-center"}>
                        <StaticQuery
                            query={graphql`
                              query {
                                allImageSharp {
                                  edges {
                                    node {
                                      fluid(maxWidth: 1200) {
                                        ...GatsbyImageSharpFluid_withWebp_noBase64
                                      }
                                    }
                                  }
                                }
                              }
                            `}
                            render={data => {
                                return (
                                    <StyledImg fadeIn={false} loading="eager" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === image);
                                    }).node.fluid} alt={imageAlt} />
                                )
                            }}
                        />
                    </div>
                    <div className={"col-md-10 col-lg-9 pl-md-1 pl-lg-2"}>
                        {children}
                    </div>
                </div>
            </StyledBlockquote>
        </StyledCitazione>
    </>

);

export default Citazione;