import React from 'react';

const Video = ({ src }) => {
    return (
        <video
            autoPlay
            muted
            loop
            style={{
                marginBottom: "-6px",
                width: "100%"
            }}
            src={src}
        >
            <source src={src} type="video/mp4" />
            Riproduzione video non supportata
        </video>
    )
};

export default Video;