import React from "react";
import {jsonConfigSostenitori, useFetch} from "../utils/config";

const Sostenitori = ({ className, background, color, id }) => {

    const { data } = useFetch(jsonConfigSostenitori, {});
    const { sostenitori: { lista } = { lista: [] } } = data;
    console.log("LISTA", lista)

    return (
        <>
            {lista.map((e, i) => {
                return (
                    <div key={i} background={background} color={color} className={`pt-3 pb-1 ${className ? className : ''}`}>
                        <div className="h4 mb-2" style={{ color: 'yellow', backgroundColor: 'black' }}>{e.nome}</div>
                        <span>"{e.testo}"</span>
                    </div>


                )
            })}
        </>
        // <StyledPhone id={id} background={background} color={color} className={`d-inline-block ${className ? className : ''}`}>
        //     <a href={"tel:"+state.data["telefono"]}>
        //         <FontAwesomeIcon icon={faPhone}/> {state.data["telefono"]}
        //     </a>
        // </StyledPhone>
    );

};

export default Sostenitori;