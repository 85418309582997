import React from "react";
import styled from "styled-components";

const StyledCard = styled.div`
    width: 100%;
    background-color: ${props => props.theme.color.white};    
    
    box-shadow: 0 20px 25px -5px rgba(0,0,0,.1), 0 10px 10px -5px rgba(0,0,0,.04);
    padding: ${props => props.withSections === true ? `0 20px` : `20px`};
    margin-bottom: ${props => props.withSections === true ? `2rem` : `0`};
    
    @media (min-width: 576px) {    
        border-radius: 8px;
    }
    
    & & h1, h2, h3, h4, h5 {
        margin: 0;
    }
    
    & p {
        margin: 15px 0;
        ${props => props.theme.font_size.small};
    }
    
    & hr {
        margin-left: -15px;
        margin-right: -15px;
        border-bottom: 2px solid ${props => props.theme.color.altwhite};
    }
    
`;

const Card = ({ withSections, className, children }) => (
    <StyledCard className={className} withSections={withSections}>
        <>
            {
                withSections
                    ?
                    <section className={`row ${className ? className : ''}`}>
                        {children}
                    </section>
                    :
                    {children}
            }
        </>
    </StyledCard>

);

export default Card;