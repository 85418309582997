import React from "react";
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import {jsonConfig, useFetch} from "../utils/config";

const StyledAnchor = styled.a`
    text-decoration: none;
`;

const StyledAnchorLink = styled(AnchorLink)`
    text-decoration: none;
`;

const StyledButton = styled.button`
    letter-spacing: .03rem;
    display: ${props => props.inline === true ? `inline-block` : `block`};
    padding: ${props => props.small === true ? `6px 11px` : `10px 15px`};
    margin: ${props => props.inline === true ? `0 10px 10px 0` : `0 0 10px 0`};
    width: ${props => props.fullwidth === true && `100%`};
    font-weight:  ${props => props.theme.font.medium};
    
    ${props => props.small === true && props.theme.font_size.xxsmall};
    ${props => props.medium === true && `font-size: 1.2rem; line-height: 1.8rem; svg {width: 0.75em;}`};
    ${props => props.medium === true && `font-family: ${props.theme.font.secondary}; font-weight: ${props.theme.font.extrabold}`};
    ${props => props.large === true && /*props.theme.font_size.larger*/ `font-size: 1.8rem; line-height: 2.4rem; ; svg {width: 1em;}`};
    ${props => props.large === true && `font-family: ${props.theme.font.secondary}; font-weight: ${props.theme.font.extrabold}`};
    
    background: ${props => props.solid !== true ? `transparent` :  (props.color || props.theme.color.black)};
    border-radius: 8px;
    border: 2px solid ${props => props.solid !== true ? (props.color || props.theme.color.black) : `transparent`};
    box-shadow: 4px 4px 0 rgba(0,0,0,.4);
    color: ${props => props.solid !== true ? (props.color || props.theme.color.black) : props.theme.color.white};
    cursor: pointer;
    outline: transparent;
    position: relative;
    -webkit-transition: box-shadow 0.15s ease;
    transition: box-shadow 0.15s ease;
    
    @media (max-width: ${props => props.theme.screen.sm}) {
        ${props => props.large === true && `font-size: 24px; line-height: 28px;`};         
    }
    
    & svg {
        ${props => props.large === true ? `vertical-align: sub;` : `vertical-align: text-top;`};        
        margin-right: 6px;
    }
    
    & :hover {
        box-shadow: 6px 6px 0 rgba(0,0,0,.4);
    }
    & :active {
        box-shadow: 0 0 0 rgba(0,0,0,.4);
    }
    & .buttonspan {
        bottom: -3px;
        left: -3px;
        position: absolute;
        right: -3px;
        top: -3px;
        z-index: 1;
    }
    & .buttonspan:nth-of-type(1):hover,
    & .buttonspan:nth-of-type(2):hover,
    & .buttonspan:nth-of-type(3):hover,
    & .buttonspan:nth-of-type(4):hover {
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        z-index: 2;
    }
    & .buttonspan:nth-of-type(1):hover ~ b:nth-of-type(1),
    & .buttonspan:nth-of-type(2):hover ~ b:nth-of-type(2),
    & .buttonspan:nth-of-type(3):hover ~ b:nth-of-type(3),
    & .buttonspan:nth-of-type(4):hover ~ b:nth-of-type(4) {
        -webkit-clip-path: inset(0 0 0 0);
        clip-path: inset(0 0 0 0);
    }
    & .buttonspan:nth-of-type(1) {
        -webkit-clip-path: polygon(0 0, 100% 0, 50% 50%, 50% 50%);
        clip-path: polygon(0 0, 100% 0, 50% 50%, 50% 50%);
    }
    & .buttonspan:nth-of-type(2) {
        -webkit-clip-path: polygon(100% 0, 100% 100%, 50% 50%);
        clip-path: polygon(100% 0, 100% 100%, 50% 50%);
    }
    & .buttonspan:nth-of-type(3) {
        -webkit-clip-path: polygon(0 100%, 100% 100%, 50% 50%);
        clip-path: polygon(0 100%, 100% 100%, 50% 50%);
    }
    & .buttonspan:nth-of-type(4) {
        -webkit-clip-path: polygon(0 0, 0 100%, 50% 50%);
        clip-path: polygon(0 0, 0 100%, 50% 50%);
    }
    & b {
        background: ${props => props.solid !== true ? (props.color || props.theme.color.black) : props.theme.color.white};
        border: 2px solid ${props => props.color || props.theme.color.black};
        border-radius: 8px;
        bottom: -3px;
        font-weight: ${props => props.large === true ? props.theme.font.extrabold : props.theme.font.medium};
        color: ${props => props.solid !== true ? props.theme.color.white : (props.color || props.theme.color.black)};
        left: -3px;
        padding: ${props => props.small === true ? `6px 11px` : `10px 15px`};
        position: absolute;
        right: -3px;
        top: -3px;
        -webkit-transition: -webkit-clip-path 0.25s ease;
        transition: -webkit-clip-path 0.25s ease;
        transition: clip-path 0.25s ease;
        transition: clip-path 0.25s ease, -webkit-clip-path 0.25s ease;
         
    }
    & b:nth-of-type(1) {
        -webkit-clip-path: inset(0 0 100% 0);
        clip-path: inset(0 0 100% 0);
    }
    & b:nth-of-type(2) {
        -webkit-clip-path: inset(0 0 0 100%);
        clip-path: inset(0 0 0 100%);
    }
    & b:nth-of-type(3) {
        -webkit-clip-path: inset(100% 0 0 0);
        clip-path: inset(100% 0 0 0);
    }
    & b:nth-of-type(4) {
        -webkit-clip-path: inset(0 100% 0 0);
        clip-path: inset(0 100% 0 0);
    }

`;

const UnstyledButton = ({ href, hrefJSON, stripHash, className, children, icon, targetBlank, formSubmit}) => {

    const state = useFetch(jsonConfig);

    return (
        <>

            { targetBlank &&

            <a href={hrefJSON ? state.data[hrefJSON] : href} target={"_blank"} rel="noopener noreferrer" style={{margin: "0", padding: "5px", whiteSpace: "nowrap"}} className={className}>

                {icon && <FontAwesomeIcon icon={icon} style={{fontSize: "1.5rem", marginRight: "5px", verticalAlign: "middle"}}/> }
                {children}

            </a>

            }

            {(!targetBlank && !formSubmit) &&

            <AnchorLink to={href || '/#'} {...stripHash && "stripHash"}>

                {icon && <FontAwesomeIcon icon={icon} /> }
                {children}

            </AnchorLink>
            }

        </>
    )
}

const Button = ({ id, href, hrefJSON, stripHash, className, children, fullwidth, inline, small, medium, large, color, solid, icon, targetBlank, formSubmit}) => {

    const state = useFetch(jsonConfig, {});

    return (
        <>
            { formSubmit &&

            <StyledAnchor type={"submit"} id={id}>

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>

                    {icon && <FontAwesomeIcon icon={icon} /> }
                    {children}

                </StyledButton>

            </StyledAnchor>

            }

            { targetBlank &&

            <StyledAnchor id={id} href={hrefJSON ? state.data[hrefJSON] : href} target={"_blank"} rel="noopener">

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>

                    {icon && <FontAwesomeIcon icon={icon} /> }
                    {children}

                </StyledButton>

            </StyledAnchor>

            }

            {(!targetBlank && !formSubmit) &&

            <StyledAnchorLink id={id} to={href || '/#'} {...stripHash && "stripHash"}>

                <StyledButton
                    className={className}
                    fullwidth={fullwidth}
                    inline={inline}
                    small={small}
                    medium={medium}
                    large={large}
                    color={color}
                    solid={solid}
                    icon={icon}
                >

                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>
                    <span className="buttonspan"></span>

                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>
                    <b aria-hidden="true">
                        {icon && <FontAwesomeIcon icon={icon} /> }
                        {children}
                    </b>

                    {icon && <FontAwesomeIcon icon={icon} /> }
                    {children}

                </StyledButton>

            </StyledAnchorLink>
            }
        </>
    )

};



export {Button, UnstyledButton};