import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faPhone} from "@fortawesome/free-solid-svg-icons"
import styled from "styled-components";
import {jsonConfig, useFetch} from "../utils/config";

const StyledPhone = styled.span`
    background-color: ${props => props.background ? props.background : props.theme.color.accent};
    color: ${props => props.color ? props.color :  `inherit`};
    padding: 3px 6px; 
    border-radius: 5px;
    white-space: nowrap; 
    font-weight: 800; 
    font-size: 120%;
    line-height: inherit;
    svg {
        width: 1em;
    },
    a {
        text-decoration: none;
    }
`;

const Phone = ({ className, background, color, id }) => {

    const state = useFetch(jsonConfig, {});

    return (
        <StyledPhone id={id} background={background} color={color} className={`d-inline-block ${className ? className : ''}`}>
            <a href={"tel:"+state.data["telefono"]}>
                <FontAwesomeIcon icon={faPhone}/> {state.data["telefono"]}
            </a>
        </StyledPhone>
    );

};

export default Phone;