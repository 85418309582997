import React from "react";
import PropTypes from 'prop-types';
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
// Favicons
// import favicon from "../images/favicon.ico";
// import safariPinnedTab from "../images/safari-pinned-tab.svg";
// import favicon32 from "../images/favicon-32x32.png";
// import favicon16 from "../images/favicon-16x16.png";
// import appleTouchIcon from "../images/apple-touch-icon.png";

const SEO = ({ lang, meta, title, description }) => {

    const { site } = useStaticQuery(
        graphql`
          query SEO {
            site {
              siteMetadata {
                author
                description
                siteUrl
                title
                locale
                og_title
                og_type    
                og_description        
                og_site_name
                og_image
                og_books_isbn
                og_books_rating_value
                og_books_rating_scale
                og_books_author
              }
            }
          }
        `
    );

    const metaTitle = title ? `${title} | ${site.siteMetadata.title}` : site.siteMetadata.title;
    const metaDescription = description ? description : site.siteMetadata.description;

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={metaTitle}
            // link={[
            //     // favicons
            //     { rel: "icon", href: favicon },
            //     { rel: "mask-icon", href: safariPinnedTab, color: "#000000" },
            //     { rel: "icon", sizes: "32x32", type: "image/png", href: favicon32 },
            //     { rel: "icon", sizes: "16x16", type: "image/png", href: favicon16 },
            //     { rel: "apple-touch-icon", href: appleTouchIcon },
            // ]}
            meta={[
                {
                    httpEquiv: `Cache-Control`,
                    content: `no-cache, no-store, must-revalidate, max-age=0`,
                },
                {
                    httpEquiv: `Pragma`,
                    content: `no-cache`,
                },
                {
                    httpEquiv: `Expires`,
                    content: `0`,
                },
                {
                    name: `description`,
                    content: metaDescription,
                },
                // Facebook OpenGraph
                {
                    property: `og:title`,
                    // content: metaTitle,
                    content: site.siteMetadata.og_title,
                },
                {
                    property: `og:description`,
                    content: site.siteMetadata.og_description,
                },
                {
                    property: `og:type`,
                    content: site.siteMetadata.og_type,
                },
                {
                    property: `og:locale`,
                    content: site.siteMetadata.locale,
                },
                {
                    property: `og:url`,
                    content: site.siteMetadata.siteUrl,
                },
                {
                    property: `og:site_name`,
                    content: site.siteMetadata.og_site_name,
                },
                {
                    property: `og:image:secure_url`,
                    content: site.siteMetadata.og_image,
                },
                {
                    property: `og:image`,
                    content: site.siteMetadata.og_image,
                },
                {
                    property: `og:books:isbn`,
                    content: site.siteMetadata.og_books_isbn,
                },
                {
                    property: `og:books:rating:value`,
                    content: site.siteMetadata.og_books_rating_value,
                },
                {
                    property: `og:books:rating:scale`,
                    content: site.siteMetadata.og_books_rating_scale,
                },
                {
                    property: `og:books:author`,
                    content: site.siteMetadata.og_books_author,
                }
            ].concat(meta)}
        >
            {/*<link rel="alternate" href="https://torredienna.it/" hrefLang="x-default"/>*/}
            <link rel="alternate" href="https://torredienna.it/index.html" hrefLang="it-it"/>
            <script type="application/ld+json">
                {`
                {
                    "@context": "http://schema.org/",
                    "@type": "Product",
                    "brand": {
                        "@type": "Brand",
                        "name": "Storie di Sicilia"
                    },
                    "name": "Umbilicus Siciliae et Trinakriae, La Torre Ottagonale di Enna",
                    "image": "https://torredienna.it/images/uset.PNG",
                    "description": "Acquista UMBILICUS SICILIAE ET TRINAKRIAE e scopri le rivoluzionarie scoperte archeologiche sulla Torre Ottagonale di Enna. Un libro imperdibile per appassionati di storia, archeologia e archeoastronomia, studenti e accademici.",
                    "productID": "isbn:9791221063011",
                    "offers": {
                        "@type": "Offer",
                        "priceCurrency": "EUR",
                        "price": "147.00",
                        "itemCondition": "new"
                    }
                }
              `}
            </script>
        </Helmet>
    );
};

SEO.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    title: PropTypes.string,
    description: PropTypes.string,
    url: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.array,
};

SEO.defaultProps = {
    lang: `it`,
    meta: [],
    description: ``,
};


export default SEO;