import React, {useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faArrowAltCircleRight, faCheckCircle} from "@fortawesome/free-solid-svg-icons"
import Anchor from './anchor';
import styled from "styled-components";

import {Button} from "./button";
import {jsonConfig, useFetch} from "../utils/config";

const StyledForm = styled.form`      

    input:not([type='checkbox']), textarea, select {        
        display: inline-block;
        width: 100%;
        padding: 10px 12px;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.43;
        color: #29303b;
        background-color: #fff;
        background-image: none;
        border: 1px solid #cacbcc;
        border-radius: 2px;
        box-shadow: none;
        transition: border-color ease-in-out .08s,box-shadow ease-in-out .08s; 
    }
    
    input[name="send_pdf"] {        
        @media (min-width: 768px) {
            width: 48%;
        }
        
        @media (min-width: 992px) and (max-width: 1199px) {
            width: 100%;
        }
    }
    
    label {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
    
    span a {
        font-size: 13px;
        color: "#2c2e31";
        margin-left: "3px";
    }
`;

const StyledForm2 = styled(StyledForm)`

     background-color: #fff;
     padding: 15px;
     color: #2c2e31;

    a {
        @media (max-width: 767px) {    
            width: 100%;            
            button {
                width: 100%;
            }
        }
        @media (min-width: 768px) and (max-width: 991px) {    
            width: 330px;
            button {
                width: 330px;
            }
        }
        @media (min-width: 992px) and (max-width: 1199px) {    
            width: 100%;
            button {
                width: 100%;
            }
        }
    }
`;

const FormError = styled.span`
    display: block;
    margin-top: -5px;
    margin-bottom: 5px;
    font-size: 80%;
    color: #dc3545;
`;

const FormButton = styled(Button)`
    
`;

const CharCounter = styled.span`
    display: block; 
    margin-top: -17px; 
    font-size: 13px; 
    font-weight: 500; 
    color: #797575;
    text-align: right;
`;

const StyledAggiornamento = styled.div`
    background-color: ${props => props.theme.color.accent};
    color: ${props => props.color ? props.color : `inherit`};;
    padding: 3px 6px;
    border-radius: 5px;
    font-weight: 800;
    line-height: inherit;
    text-align: center;
`;

const Aggiornamento = ({color}) => {

    const { data } = useFetch(jsonConfig, {});

    const {ultimo_aggiornamento_pdf_email} = data;

    const ultimoAggiornamento = new Date(ultimo_aggiornamento_pdf_email).toLocaleDateString('it-IT',{ year: 'numeric', month: 'long', day: 'numeric' })


    return (
        <StyledAggiornamento color={color}>
            Ultimo aggiornamento: <span className={"d-inline-block"}>{ultimoAggiornamento}</span>
        </StyledAggiornamento>
    )
};


const SendPDFs = ({children, className, style, id}) => {

    const [email, setEmail] = useState('');
    const [privacy, setPrivacy] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [confirmed, setConfirmed] = useState(false);
    /*const [hidden, setHidden] = useState(true);*/

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);

        if (email && privacy) {
            console.log('email:', email);
            setConfirmed(true);
        }
    };

    return (
        <>
        {(!submitted || !confirmed)  &&
        <StyledForm2 id={id} name={id} onSubmit={handleSubmit}>
            <>
            <label htmlFor={"send_pdf"} className={"sr-only"}>Scrivi qui la tua email</label>
            <input
                type="text"
                name="send_pdf"
                value={email}
                autoComplete="off"
                onChange={e => setEmail(e.target.value)}
                placeholder={"Scrivi qui la tua email"}
                aria-label="Scrivi qui la tua email"
                className={"mr-2"}
            />

            <FormButton formSubmit solid inline color={"#db4437"} /*disabled={loggingIn}*/>
                {/*{loggingIn ? ButtonIconSpinner : ButtonIconArrow}*/}
                <FontAwesomeIcon icon={faArrowAltCircleRight} style={{fontSize: "20px"}} /> OK, voglio ricevere i pdf
            </FormButton>
            </>
            {submitted && (!email) &&
            <FormError>Devi inserire un indirizzo email valido</FormError>
            }
            <input
                type="checkbox"
                id="check-privacy-1"
                name="check-privacy-1"
                onChange={() => setPrivacy(!privacy)}
            />
            <label
                htmlFor="check-privacy-1"
                className={"pl-1"}
                style={{color: `${submitted && !privacy ? "#db4437" : "inherit"}`, fontWeight: "700", marginRight: "5px"}}

            >
                Dichiaro di aver preso visione dell'informativa sulla privacy
            </label>
            <Anchor internal href={"/privacy"}>link</Anchor>
            {submitted && (!privacy) &&
            <FormError>Devi confermare di aver letto la privacy policy</FormError>
            }
        </StyledForm2>
        }
        {(submitted && privacy && confirmed) &&
        <>
        <p className={"h3"} style={{color: "#59a139"}}><FontAwesomeIcon icon={faCheckCircle} /> Grazie!</p>
        <p className={"h4"} style={{color: "#59a139"}}>Riceverai a breve i pdf nella tua casella email</p>
        </>
        }
        </>
    )
};



const SendPrenotazione = ({children, className, style, id}) => {

    const maxChars = 150;

    const [nome, setNome] = useState('');
    const [tel, setTel] = useState('');
    const [info, setInfo] = useState('');
    const [copie, setCopie] = useState('1');
    const [chars, setChars] = useState(maxChars);
    const [privacy, setPrivacy] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [confirmed, setConfirmed] = useState(false);

    const handleChangeInfo = (e) => {
        setInfo(e);
        const charCount = e.length;
        const charsLeft = maxChars - charCount;
        setChars(charsLeft);
    };

    const handleChangeTel = (e) => {
      if (e.target.validity.valid) {
          setTel(e.target.value);
      }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitted(true);

        if (nome.length >= 3 && tel.length >= 8 && privacy) {
            setConfirmed(true);
            console.log("OK: ", 'nome:', nome, 'tel:', tel, "info:", info, "copie:", copie);
        }
    };

    return (
        <>
            <StyledForm id={id} name={id} onSubmit={handleSubmit}>
                <div className={"row"}>
                    <div className={"col-lg-6"}>
                        <label htmlFor={"nome"} className={"sr-only"}>"Nome *campo obbligatorio"</label>
                        <input
                            type="text"
                            name="nome"
                            value={nome}
                            autoComplete="off"
                            maxLength={"50"}
                            onChange={e => setNome(e.target.value)}
                            placeholder={"Nome *campo obbligatorio"}
                            aria-label="Nome *campo obbligatorio"
                        />
                        {submitted && (!nome || nome.length < 3) &&
                        <FormError>Minimo 3 massimo 50 caratteri</FormError>
                        }
                    </div>
                    <div className={"col-lg-6"}>
                        <label htmlFor={"tel"} className={"sr-only"}>"Numero di Cellulare *campo obbligatorio"</label>
                        <input
                            type='tel'
                            pattern="^-?[0-9]\d*$"
                            name="tel"
                            value={tel}
                            autoComplete="off"
                            onChange={e => handleChangeTel(e)}
                            placeholder={"Numero di Cellulare *campo obbligatorio"}
                            aria-label="Numero di Cellulare *campo obbligatorio"
                        />
                        {submitted && (!tel) &&
                        <FormError>Inserire il numero di telefono</FormError>
                        }
                        {submitted && (tel && tel.length < 8) &&
                        <FormError>Inserire il numero di telefono (minimo 8 cifre)</FormError>
                        }
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-lg-6"}>
                        <label htmlFor={"info"} className={"sr-only"}>"Quando vuoi essere contattato?"</label>
                        <textarea
                            rows="2"
                            name="info"
                            maxLength={maxChars}
                            onChange={e => handleChangeInfo(e.target.value)}
                            value={info}
                            placeholder={"Quando vuoi essere contattato?"}
                            aria-label="Quando vuoi essere contattato?"
                        ></textarea>
                        <CharCounter>{chars} caratteri rimanenti</CharCounter>
                    </div>
                    <div className={"col-lg-6 pb-3"}>
                        <label htmlFor={"copie"} style={{display: "inline-block", padding: "0 0 1px 12px", color: "#797575"}}>Quante copie desideri ordinare?</label>
                        <select
                            name="copie"
                            value={copie}
                            onChange={e => setCopie(e.target.value)}
                            aria-label="Quante copie desideri ordinare?"
                        >
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                        </select>
                    </div>
                </div>
                <div className={"row"}>
                    <div className={"col-12 pb-3"}>
                        <input
                            type="checkbox"
                            id="check-privacy-2"
                            name="check-privacy-2"
                            onChange={() => setPrivacy(!privacy)}
                        />
                        <label
                            htmlFor="check-privacy-2"
                            className={"pl-1"}
                            style={{color: `${submitted && !privacy ? "#db4437" : "inherit"}`, fontWeight: "700", marginRight: "5px"}}

                        >
                            Dichiaro di aver preso visione dell'informativa sulla privacy
                        </label>
                        <Anchor internal href={"/privacy"}>link</Anchor>
                        {submitted && (!privacy) &&
                        <FormError>Devi confermare di aver letto la privacy policy</FormError>
                        }
                    </div>
                </div>
                { !confirmed ?
                <div className={"row"}>
                    <div className={"col-12"} style={{textAlign: "center"}}>
                        <Button formSubmit solid color={"#db4437"} icon={faArrowAltCircleRight} large>
                            Invia la prenotazione
                            <span style={{display: "block", fontWeight: "800", whiteSpace: "nowrap", fontSize: "14px", lineHeight: "18px"}}>senza vincolo di acquisto</span>
                        </Button>
                    </div>
                </div>
                :
                <div className={"row"}>
                    <div className={"col-12"} style={{textAlign: "center"}}>
                        <p className={"h3 mb-0"} style={{color: "#59a139", fontSize: "40px"}}><FontAwesomeIcon icon={faCheckCircle} /> Grazie!</p>
                        <p className={"h4 mt-2"} style={{color: "#59a139", fontSize: "20px"}}>Richiesta inviata con successo</p>
                    </div>
                </div>
                }
            </StyledForm>
        </>
    )
};

export {Aggiornamento, SendPDFs, SendPrenotazione};