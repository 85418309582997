import React from "react"
import {graphql, StaticQuery} from "gatsby"
import Img from 'gatsby-image'
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFilePdf, faArrowAltCircleRight}  from "@fortawesome/free-solid-svg-icons"
import {ImageSliderFade} from "./imageSliderFade";
import {Button} from "./button"
import Video from "../components/video.js"



const StyledContainerFluid = styled.div`    
    background-color: ${props => props.background ? props.background : `transparent`};    
    
`;

const StyledImg = styled(Img)`
    
`;

const FullWidth = ({ background, className, children, id, style, h1, h1Class, customH1, h2, h2Class, textColor, video, imageSlider, image, imageAlt }) => {



    return (

        <StyledContainerFluid id={id} background={background}>
            <div className={"container"} style={{maxWidth: "unset"}}>
                <section className={`row justify-content-center ${className ? className : ''}`} style={style}>

                    {image &&
                    <div className={`col-12 px-0 d-lg-none`}>
                        <StaticQuery
                            query={graphql`
                                  query {
                                    allImageSharp {
                                      edges {
                                        node {
                                          fluid(maxWidth: 1200) {
                                            ...GatsbyImageSharpFluid_withWebp_noBase64
                                          }
                                        }
                                      }
                                    }
                                  }
                                `}
                            render={data => {
                                return (
                                    <StyledImg fadeIn={false} loading="eager" className="d-lg-none" fluid={data.allImageSharp.edges.find((element) => {
                                        // Match string after final slash
                                        return (element.node.fluid.src.split('/').pop() === image);
                                    }).node.fluid} alt={imageAlt}/>
                                )
                            }}
                        />
                    </div>
                    }

                    {imageSlider &&
                    <div className={`col-12 px-0 d-lg-none`}>
                        <ImageSliderFade />
                    </div>
                    }

                    <div className={`col-12 col-sm-11 col-md-10 col-lg-6 justify-content-center align-self-center px-3 px-lg-5`}>
                        { customH1 ?
                            <h1 className={`${h1Class ? h1Class+'  pt-4 px-1' : 'pt-4 px-1'}`} style={{color: textColor, maxWidth: "650px", fontWeight: "600",  opacity: ".9"}}>
                                La Torre ottagonale di Enna: il <span style={{color: "#db4437"}}>cuore</span> della Sicilia
                            </h1>
                            :
                            <h1 className={`${h1Class ? h1Class+'  pt-4 px-1' : 'pt-4 px-1'}`} style={{color: textColor, maxWidth: "650px", fontWeight: "600",  opacity: ".9"}}>
                                {h1}
                            </h1>
                        }
                        { h2 &&
                            <>
                                <hr style={{borderColor: textColor,  opacity: ".9"}} />
                                <h2 className={`${h2Class ? h2Class+' mx-auto pb-4 px-1' : 'pb-4 px-1'}`} style={{color: textColor, fontSize: "1.4rem", lineHeight: "1.9rem",  opacity: ".9"}}>
                                    {h2}
                                </h2>
                            </>
                        }
                        <div>
                            <FontAwesomeIcon icon={faArrowAltCircleRight} style={{width: "62px", color: "rgb(219, 68, 55)", float: "left"}} className={"mr-2"} />
                            <Button id={"articoli_pdf"} targetBlank solid color={"#59a139"} icon={faFilePdf} large hrefJSON={"href_articoli"} className={"mb-4"}>Scarica gli articoli di giornale</Button>
                        </div>
                    </div>

                    <div className={`col-lg-6 justify-content-center align-self-center d-none d-lg-block px-lg-0`}>

                        {video &&
                        <Video
                            autoPlay
                            muted
                            loop
                            src={video}
                        >
                            <source src={video} type="video/mp4"/>
                        </Video>
                        }

                        {imageSlider &&
                        <ImageSliderFade />
                        }
                    </div>

                    {children}

                </section>
            </div>
        </StyledContainerFluid>

    );
}

export {FullWidth};