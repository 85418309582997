import React from "react"
import { graphql, StaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import SimpleReactLightbox, { SRLWrapper } from "simple-react-lightbox";

const ImageGallery = () => {
    const options = {
        settings: {
            transitionSpeed: 900,
            disablePanzoom: true
        },
        caption: {
            showCaption: false
        },
        buttons: {
            showDownloadButton: false
        }
    };

    return (
        <StaticQuery
            query={graphql`
              query {
                gallery: allFile(
                  filter: { relativeDirectory: { eq: "gallery" } }
                  sort: { fields: base, order: ASC }
                ) {
                  edges {
                    node {
                      id
                      base
                      publicURL
                      childImageSharp {
                        gatsbyImageData(
                          transformOptions: { fit: COVER }
                          placeholder: BLURRED
                          webpOptions: { quality: 70 }
                        )
                      }
                    }
                  }
                }
              }
            `}
            render={data => {
                return (
                    <SimpleReactLightbox>
                        <SRLWrapper options={options}>
                            <div className="row py-3">
                                {data.gallery.edges.map(({ node }) => (
                                    <div className="col-6 col-lg-3 justify-content-center align-self-center" key={node.id}>
                                        {node.childImageSharp ? (
                                            <a href={node.publicURL} title="Ingrandisci l'immagine">
                                                <GatsbyImage image={node.childImageSharp.gatsbyImageData} alt={node.base.split('_').join(' ').split('.')[0]} />
                                            </a>
                                        ) : (
                                            <a href={node.publicURL} title="Ingrandisci l'immagine">
                                                <img src={node.publicURL} alt={node.base.split('_').join(' ').split('.')[0]} style={{ width: "100%" }} />
                                            </a>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </SRLWrapper>
                    </SimpleReactLightbox>
                )
            }}
        />
    )
};

export default ImageGallery;
