import React from "react";
import styled from "styled-components";

import {jsonConfig, useFetch} from "../utils/config";

const StyledFooter = styled.section`
  padding:15px;
  color: ${props => props.color ? props.color :  props.theme.color.altwhite};
  background-color: ${props => props.background ? props.background : props.theme.color.black};
  text-align: center;
  letter-spacing: ${props => props.background === props.theme.color.black ? "0.03rem" : "inherit"};
  font-size: 14px;
  
  hr {
    border-bottom: 1px solid ${props => props.color ? props.color :  props.theme.color.altwhite};
  }
`;

const Footer = ({id, background, color, style, className, children}) => {

    return (
        <StyledFooter id={id} background={background} color={color} style={style}>
            <div className={"container"}>
                <section className={`row ${className ? className : ''}`}>
                    {children}
                </section>
            </div>
        </StyledFooter>
    )
};

const Copyright = ({from}) => (
        <>
            &copy; {from} - {new Date().getFullYear()}
        </>
);

const Patamu = () => {

    const { data } = useFetch(jsonConfig, {});

    const { patamu } = data;

    return (
        <>
        {(patamu !== undefined && patamu !== "") ?
            <>
            <hr />
            <div>
                Tutti i contenuti del sito sono tutelati dal plagio su <b>patamu.com</b> con numero deposito <b>{patamu}</b>
            </div>
            </>
            :
            ""
        }
        </>
    );

};

export {Footer, Copyright, Patamu}
