import React from 'react';
import { useStaticQuery, graphql } from "gatsby"
import {
    FacebookShareButton,
    FacebookIcon,
    WhatsappShareButton,
    WhatsappIcon,
    TelegramShareButton,
    TelegramIcon
} from 'react-share';
import styled from "styled-components";

const UlList = styled.ul`
    list-style: none;
    margin: 0;
    padding: 0;
    
    li {
        display: inline-block;
        margin: 2px;
        
        button {
            &::before {
                background-color: transparent!important;
            } 
        }        
    }
`;

const Share = ({title, url}) => {
    const data = useStaticQuery(graphql`
        query ShareQuery {
            site {
                siteMetadata {
                    defaultTitle: title
                    url: siteUrl
                }
            }
        }
    `);

    title = title !== undefined ? title + ` | ` + data.site.siteMetadata.defaultTitle : data.site.siteMetadata.defaultTitle;

    return (
        <UlList>
            <li title={"Condividi su Facebook"}>
                <FacebookShareButton url={url}>
                    <FacebookIcon size={32} round={true} />
                </FacebookShareButton>
            </li>
            <li title={"Condividi con WhatsApp"}>
                <WhatsappShareButton title={title} url={url}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
            </li>
            <li title={"Condividi con Telegram"}>
                <TelegramShareButton title={title} url={url}>
                    <TelegramIcon size={32} round={true} />
                </TelegramShareButton>
            </li>
        </UlList>
    );
};

export default Share;