import {useEffect, useState} from "react";

export const jsonConfig = "https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/torredienna/torre_di_enna_config.json";
export const jsonConfigSostenitori = "https://zenitsecurity.s3.eu-south-1.amazonaws.com/websites/torredienna/sostenitori.json";

export const useFetch = (url, options = {}) => {

    const [data, setData] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({ status: false, message: '' });

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const response = await fetch(url, options);
                if (!response.ok) throw new Error('Error fetching data.');
                const json = await response.json();
                setData(json);
                setLoading(false);
            } catch (error) {
                setError({ status: true, message: error.message });
                setLoading(false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return { data, loading, error };

}

export const useWindowWidth = () => {

    const isBrowser = typeof window !== 'undefined';
    const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

    useEffect(() => {
        if (!isBrowser) return false;

        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    });

    return width
};

