import React from "react";
import { ThemeProvider } from "styled-components"

import theme from "../style/theme"
import GlobalStyles from "../style/GlobalStyles"
import Menu from "../components/menu.js";

const Layout = ({ children, location }) => {
    return (
        <ThemeProvider theme={theme}>
            <div id="top" style={{marginTop: `${theme.header.height}`, paddingTop: "10px"}}>
                <GlobalStyles/>
                <Menu location={location} />
                {children}
            </div>
        </ThemeProvider>
    )
};

export default Layout;